<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw2"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_HS_POWERBALL_3M}"
                 :to="{path:'/front/minigame/hspowerball3m', query: {t: new Date().getTime()}}">파워볼 3분
    </router-link>

    <router-link tag="button" class="bw2"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_HS_POWERBALL_5M}"
                 :to="{path:'/front/minigame/hspowerball5m', query: {t: new Date().getTime()}}"> 파워볼 5분
    </router-link>
  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompHSGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>