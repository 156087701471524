<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_EOS_POWERBALL_1M}"
                 :to="{path:'/front/minigame/eospowerball1m', query: {t: new Date().getTime()}}">EOS파워볼 1분
    </router-link>

    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_EOS_POWERBALL_3M}"
                 :to="{path:'/front/minigame/eospowerball3m', query: {t: new Date().getTime()}}">EOS파워볼 3분
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_EOS_POWERBALL_5M}"
                 :to="{path:'/front/minigame/eospowerball5m', query: {t: new Date().getTime()}}"> EOS파워볼 5분
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_POWERSADALI}"
                 :to="{path:'/front/minigame/powersadali', query: {t: new Date().getTime()}}"> 파워사다리
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SPEEDKINO}"
                 :to="{path:'/front/minigame/speedkino', query: {t: new Date().getTime()}}"> 스피드 키노
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_KINOSADALI}"
                 :to="{path:'/front/minigame/kinosadali', query: {t: new Date().getTime()}}"> 키노 사다리
    </router-link>
  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompEOSGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>
.mt-1 {
  margin-top: 10px;
}
</style>