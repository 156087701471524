<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw2"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BAKARA_LOTUS_1}"
                 :to="{path:'/front/minigame/lotus_bakara', query: {t: new Date().getTime()}}">바카라
    </router-link>

    <router-link tag="button" class="bw2"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_ODDEVEN_LOTUS}"
                 :to="{path:'/front/minigame/lotus_oddeven', query: {t: new Date().getTime()}}">홀짝
    </router-link>
    <!--            <router-link tag="button" class="btn03 bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_LOTUS_DRAGONTIGER}"-->
    <!--                         :to="{path:'/leisure/lotus_dragontiger', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 드래곤타이거</router-link>-->

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompLotusGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>