<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_PAMGAME_MARIO}"
                 :to="{path:'/leisure/pamgamemario', query: {t: new Date().getTime()}}"> 마리오
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_PAMGAME_SLOT}"
                 :to="{path:'/front/minigame/pamgameslot', query: {t: new Date().getTime()}}"> 티몬품바
    </router-link>
    <router-link tag="button" class="bw3"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_PAMGAME_DRAGONTIGER}"
                 :to="{path:'/front/minigame/pamgamedragontiger', query: {t: new Date().getTime()}}"> 드래곤타이거
    </router-link>
  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompPamGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>