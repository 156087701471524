<template>

  <div class="leisure_button_group_sub">
    <!--            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M}"-->
    <!--                         :to="{path:'/leisure/gamemoassd1', query: {t: new Date().getTime()}}">별다리 1분</router-link>-->
    <!--            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M}"-->
    <!--                         :to="{path:'/leisure/gamemoassd5', query: {t: new Date().getTime()}}">별다리 5분</router-link>-->

    <!--            <router-link tag="button" class="bw2 mt-1"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M}"-->
    <!--                         :to="{path:'/leisure/gamemoabubblesdl1', query: {t: new Date().getTime()}}">보글보글 사다리 1분</router-link>-->
    <!--            <router-link tag="button" class="bw2 mt-1"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M}"-->
    <!--                         :to="{path:'/leisure/gamemoabubblesdl3', query: {t: new Date().getTime()}}">보글보글 사다리 3분</router-link>-->

    <!--            <router-link tag="button" class="bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_GAMEMOA_ACE}"-->
    <!--                         :to="{path:'/leisure/gamemoaace', query: {t: new Date().getTime()}}">에이스 홀짝</router-link>-->

    <router-link tag="button" class="bw1"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SPEEDGAME_PDALI}"
                 :to="{path:'/minigame/speedgamepdali', query: {t: new Date().getTime()}}">황금돼지 사다리
    </router-link>


  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompGameMoaGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>
.mt-1 {
  margin-top: 5px;
}
</style>