<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw1"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUTDA}"
                 :to="{path:'/front/minigame/sutda', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 섯다
    </router-link>

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompCrownGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>