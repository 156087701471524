<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw4"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NEXT_RING1M}"
                 :to="{path:'/front/minigame/next_ring1m', query: {t: new Date().getTime()}}"> 1분링
    </router-link>
    <router-link tag="button" class="bw4"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NEXT_BACCARA}"
                 :to="{path:'/front/minigame/next_bakara', query: {t: new Date().getTime()}}"> 바카라
    </router-link>
    <router-link tag="button" class="bw4"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NEXT_SADALI}"
                 :to="{path:'/front/minigame/next_sadali', query: {t: new Date().getTime()}}"> 사다리
    </router-link>
    <!--            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NEXT_POWERBALL}"-->
    <!--                         :to="{path:'/front/minigame/next_power3m', query: {t: new Date().getTime()}}"> 파워볼</router-link>-->

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompNextGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>