<template>
    <div>
        <div class="leisure_button_group_sub">


            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE}"
                         :to="{path:'/front/minigame/bet365_superleague', query: {t: new Date().getTime()}}">
                  슈퍼리그</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP}"
                         :to="{path:'/front/minigame/bet365_eurocup', query: {t: new Date().getTime()}}">
               유로컵</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP}"
                         :to="{path:'/front/minigame/bet365_worldcup', query: {t: new Date().getTime()}}">
               월드컵</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP}"
                         :to="{path:'/front/minigame/bet365_premiership', query: {t: new Date().getTime()}}">
             프리미어</router-link>
            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER}"
                         :to="{path:'/front/minigame/bet365_water', query: {t: new Date().getTime()}}">
              워터포드</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER}"
                         :to="{path:'/front/minigame/bet365_baker', query: {t: new Date().getTime()}}">
            베이커</router-link>


            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK}"
                         :to="{path:'/front/minigame/bet365_goldenhill', query: {t: new Date().getTime()}}">
               골든힐</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK}"
                         :to="{path:'/front/minigame/bet365_hillside', query: {t: new Date().getTime()}}">
              힐사이드</router-link>

            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_2}"
                         :to="{path:'/front/minigame/bet365_horserace2', query: {t: new Date().getTime()}}">
               브리타니아
            </router-link>
            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_3}"
                         :to="{path:'/front/minigame/bet365_horserace3', query: {t: new Date().getTime()}}">
            페스티벌
            </router-link>
            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_4}"
                         :to="{path:'/front/minigame/bet365_horserace4', query: {t: new Date().getTime()}}">
               빅토리아
            </router-link>
        </div>
    </div>




</template>
<script>
    import leisureConst from "@/common/leisureConst";

    export default {
        name: "LeisureCompBet365GameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>